import React from 'react'
import styles from './ButtonLink.module.scss'

import { ButtonHTMLAttributes } from 'react'
import { classNames } from 'src/ui/utils/classnames'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode
  'data-testid'?: string
}

export const ButtonLink = React.forwardRef<HTMLButtonElement, Props>(
  function ButtonLink(props, ref) {
    const { className, ...buttonProps } = props
    return (
      <button
        ref={ref}
        data-testid={props['data-testid']}
        {...buttonProps}
        className={classNames(styles.container, className)}
      >
        {props.children}
      </button>
    )
  },
)
